import {defineStore} from "pinia";
import {useLoadingStore} from "@/store/loading";
import LoginHistoryService from "@/core/services/LoginHistoryService";

export const useLoginStore = defineStore('loginStore', {
  state: () => ({
    page: {
      total: 0,
      data: [],
      totalPages: 0,
    },
  }),
  actions: {
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      LoginHistoryService.findAll<any>(filter).then(res => {
        this.page = res
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },
  }
})